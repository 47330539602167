// School Name Search Engine V1.0
// Create: 2024/1/8 N.Miyazawa
// Begin Script

// Begin Import
import React, { useState } from 'react';
import './App.css';
import headerImg from './header.png';

import algoliasearch from 'algoliasearch/lite';
import { MultipleQueriesQuery } from '@algolia/client-search';
import {
  Configure,
  DynamicWidgets,
  Highlight,
  Hits,
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
  PoweredBy,
} from 'react-instantsearch';
import type { Hit } from 'instantsearch.js';
import { Panel } from './Panel';

import ReactGA from 'react-ga4';

// End Import

// Begin GA4

// Google Analytics 測定 ID を入力して設定
ReactGA.initialize('G-F9VTCC49CY');
ReactGA.send('pageview');

// END GA4

// Begin Algolia Setting
const searchClient = algoliasearch(
  '9GDYTM7EKI',
  '9de0973eaffbcf8459bbbce76ab00c14'
);

searchClient.addAlgoliaAgent = () => {
  const originalSearch = searchClient.search;
  searchClient.search = (requests: any[] | readonly MultipleQueriesQuery[]) => {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return originalSearch(requests);
  };
};

const future = { preserveSharedStateOnUnmount: true };

// END Algolia Setting

// Begin View
export function App() {
  const [] = useState('');
  return (
    <div>
      <header className="header">
        <h1 className="header-title">
          <a href="/">
            <img src={headerImg} height="80px" alt="学校名検索" />
          </a>
        </h1>
        <p className="header-subtitle">
          <a
            href="https://www.mext.go.jp/b_menu/toukei/mext_01087.html"
            target="_blank"
          >
            「文部科学省　学校コード」 令和5年5月1日時点（確定版）
          </a>
          を基に構築しています。
          <br />
          本サイトは
          <a href="https://seitokai.jp" target="_blank">
            生徒会.jp(一般社団法人 生徒会活動支援協会)
          </a>
          が運営しています。
        </p>
      </header>

      <div className="container">
        <InstantSearch
          searchClient={searchClient}
          indexName="school_code"
          future={future}
          insights
        >
          <Configure hitsPerPage={5} />
          <div className="search-panel">
            <div className="search-panel__filters">
              <DynamicWidgets fallback={RefinementList}>
                <Panel header="学校種">
                  <RefinementList attribute="学校種" />
                </Panel>
                <Panel header="都道府県">
                  <RefinementList attribute="都道府県" />
                </Panel>
                <Panel header="設置区分">
                  <RefinementList attribute="設置区分" />
                </Panel>
                <Panel header="本分校">
                  <RefinementList attribute="本分校" />
                </Panel>
              </DynamicWidgets>
            </div>

            <div className="search-panel__results">
              <PoweredBy />
              <br />
              <SearchBox
                placeholder="キーワードを入力…"
                className="searchbox"
              />

              <Hits hitComponent={Hit} />

              <div className="pagination">
                <Pagination />
              </div>
            </div>
          </div>
        </InstantSearch>
      </div>
      {/* <footer>
      <p>お問合せ：system [atmark] seitokai.jp データの最新性･正確性は保証いたしかねます。</p>
      </footer> */}
    </div>
  );
}

// END View

// Begin Hit Process
type HitProps = {
  hit: Hit;
};

function Hit({ hit }: HitProps) {
  return (
    <article>
      <h1>
        <Highlight attribute="学校名" hit={hit} />
      </h1>
      <p>
        <Highlight attribute="学校所在地" hit={hit} />
      </p>
    </article>
  );
}
// END Hit Process
// END Script
